import * as React from 'react';
import './styles/main.css'
import './styles/utils/constants.css'
import Home from "./components/Home";


function App() {
    return (
        <Home/>
    );
}

export default App;
