import {useContext, useState} from "react";
import '../../../../../../styles/components/search-bar/date-range-picker.css'
import { ReactComponent as ArrowLeft} from "../../../../../../static/icons/arrow_left.svg";
import { ReactComponent as ArrowRight} from "../../../../../../static/icons/arrow_right.svg";
import {mod} from "../../../../../../utils/ToolFunctions";
import {startOfToday, startOfMonth, getDaysInMonth, endOfYear} from "date-fns";
import {MONTHS, WEEKDAYS} from "../../../../../../utils/Constants";
import {SearchBarContext} from "../../../../../../context/SearchBarContext";
import {DateFieldsContext} from "../../../../../../context/DateFieldsContext";


const DateRangePicker = ({className}) => {

    const today = new Date()
    const {startDate, setStartDate,endDate, setEndDate, maxMonth} = useContext(SearchBarContext)
    const {setShowMenu, clicked, setClicked, currentMonth, setCurrentMonth} = useContext(DateFieldsContext)

    const [hoverDate, setHoverDate] = useState(null);

    //Right Calendar
    const nextMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1);

    const canChangeMonth = (increment) => {
        const newMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + increment, 1)
        return newMonth >= startOfMonth(today) && newMonth <= maxMonth
    };
    const changeMonth = (increment) => {
        if (canChangeMonth(increment)) {
            const newMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + increment, 1)
            setCurrentMonth(newMonth)
        }
    };

    const handleYearButtonClick = () => {
        setStartDate(startOfToday())
        setEndDate(endOfYear(today))
        setShowMenu(false)
    }
    const handleDateClick = (date) => {
        if (date >= startOfToday() && date < maxMonth){
            // Clicked on StartDate (default mode)
            if (clicked.startDate && !clicked.endDate){
                // Both startDate and endDate selected
                if (startDate && endDate) {
                    // startDate < endDate <= date
                    if (date >= endDate) setEndDate(null)

                    setClicked({startDate: false, endDate: true})
                    setStartDate(date)
                }
                else if (endDate) {
                    if (date >= endDate) {
                        setEndDate(null)
                        setStartDate(date)
                        setClicked({startDate: false, endDate: true})
                    }else{
                        setStartDate(date)
                        setShowMenu(false)
                    }
                }
                // Nothing selected
                else {
                    setStartDate(date)
                    setClicked({startDate: false, endDate: true})
                }
            }
            // Clicked on EndDate
            else if (clicked.endDate && !clicked.startDate) {
                // Both startDate and endDate are selected
                if (endDate && startDate) {
                    if (date <= startDate){
                        setStartDate(null)
                        setClicked({startDate: true, endDate: false})
                    }
                    else setShowMenu(false)
                    setEndDate(date)
                }
                // Only EndDate Selected
                else if (startDate) {
                    if (date < startDate) {
                        setStartDate(null)
                        setEndDate(date)
                        setClicked({startDate: true, endDate: false})
                    }else {
                        setEndDate(date)
                        setShowMenu(false)
                    }

                }
                else {
                    setEndDate(date)
                    setClicked({startDate: true, endDate: false})
                }
            }
        }

    };

    const isInRange = (date) => {

        if (startDate && endDate) {
            // Days between StartDate and EndDate
            return date > startDate && date < endDate;
        }

        if (hoverDate && hoverDate >= startOfToday() && hoverDate <= maxMonth){
            // Clicked on StartDate (default mode)
            if (!clicked.startDate && clicked.endDate && startDate) return date > startDate && date < hoverDate
            // Clicked on StartDate (default mode)
            if (!clicked.endDate && clicked.startDate && endDate)return date > hoverDate && date < endDate
        }

        return false;
    };

    const dayStyle = (date) => {
        let className = ""

        const isStart = startDate?.toDateString() === date.toDateString();
        const isEnd = endDate?.toDateString() === date.toDateString();
        const isHovered = hoverDate?.toDateString() === date.toDateString();
        const inRange = isInRange(date);
        const hasPassed = date < startOfToday() || date > maxMonth
        const isRightColumn = date.getDay() === 6
        const isLeftColumn = date.getDay() === 0

        if (isStart) {
            className += ' start-date'
            if (endDate || (hoverDate && hoverDate >= startDate)) className += ' in-range'
        }
        else if (isEnd) {
            className += ' end-date'
            if (startDate) className += ' in-range'
            if (!startDate && hoverDate && hoverDate < endDate) className += ' in-range'
        }
        else if (hasPassed) className += ' passed'
        else if (isHovered) {
            if (endDate && !startDate && date < endDate) className += ' start-date in-range'
            if (startDate && !endDate && date > startDate) className += ' end-date in-range'
        }
        if (inRange) className += ' between'
        if (isRightColumn) className += ' right-column'
        if (isLeftColumn) className += ' left-column'

        return className
    }

    const renderMonth = (month, position) => {

        const days = getDaysInMonth(month);
        const firstDay = mod(startOfMonth(month).getDay(), 6);
        const calendarDays = [];

        for (let i = 0; i < firstDay; i++) {
            calendarDays.push(<div key={`empty-${i}-${month}`} className="calendar-day empty"></div>);
        }

        for (let day = 1; day <= days; day++) {
            const date = new Date(month.getFullYear(), month.getMonth(), day);

            calendarDays.push(
                <div
                    key={`${day}-${month}`}
                    onClick={() => handleDateClick(date)}
                    onMouseEnter={() => setHoverDate(date)}
                    onMouseLeave={() => setHoverDate(null)}
                    className={`calendar-day ${dayStyle(date)}`}>
                    <p className="day-number">{day}</p>
                </div>
            );
        }


        return (
            <div className={`date-range-picker-card calendar-position-${position}`}>
                <div className="month-calendar">
                    <div className="month-header">
                        <ArrowLeft  fill={"#3E3E3E"} onClick={() =>  changeMonth(-1)}
                                    className={`arrow-icon left-arrow ${!canChangeMonth(-1) ? 'not-allowed' : ''}`}/>
                        <p className={"month-title"}>{MONTHS[month.getMonth()]} {month.getFullYear()}</p>
                        <ArrowRight fill={"#3E3E3E"} onClick={() => changeMonth(1)} 
                                    className={`arrow-icon right-arrow ${!canChangeMonth(1) ? 'not-allowed' : ''}`}/>

                    </div>
                    <div className="calendar-grid">
                        {// Letters of calendar (M,T,W,T,F,S,S)
                            WEEKDAYS.map((day) => {
                                return (<div key={day} className="calendar-weekday">{day[0]}</div>);
                            })
                        }
                        {calendarDays}
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className={`date-range-picker ${className || ''}`}>
            <div className="months">
                {renderMonth(currentMonth, "left")}
                {renderMonth(nextMonth, "right")}
            </div>

            <div className="year-button" onClick={handleYearButtonClick}>
                <p className={"title"}>This year</p>
            </div>
        </div>
    )
}

export default DateRangePicker;