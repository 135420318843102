import Rocket from "../../../static/icons/rocket_launch.svg";
import * as React from "react";
import '../../../styles/components/optimize-button.css'


const OptimizeButton = ({handlePost}) => {

    return (
        <button className="optimize-button" onClick={handlePost}>
            <img src={Rocket} alt=""/>
            <p className="title">Optimize</p>
        </button>
    )
}

export default OptimizeButton;