import '../../styles/components/advanced-mode.css'
import React, {memo, useContext, useEffect, useRef, useState} from "react";
import Info from "../../static/icons/info.svg";
import {
    CALENDAR_MODE_CONFIG,
    CALENDAR_MODE_STATES,
    DAYS_OF_WEEK,
    PAINT_MODE_CONFIG,
    PAINT_MODE_STATES, WEEKEND_DAYS_CONFIG
} from "../../utils/Constants";
import SwitchButton from "../Buttons/SwitchButton";
import {capitalizeFirstLetter} from "../../utils/ToolFunctions";
import {ButtonsSectionContext} from "../../context/ButtonsSectionContext";
import AdvancedModeOption from "./AdvancedModeOption";
import {AdvancedModeContext} from "../../context/AdvancedModeContext";
import ResetButton from "../Buttons/ResetButton";
const AdvancedMode = () =>{
    const {setPaintMode, setCalendarMode} = useContext(ButtonsSectionContext)

    const dropdownRef = useRef(null);

    const [showMenu, setShowMenu] = useState(false)
    const [dropdownOptions, setDropdownOptions] = useState({})
    const [selectedOption, setSelectedOption] = useState(null)


    const handleDropdownOptionClick = (option) => {
        if (dropdownOptions === PAINT_MODE_STATES) {
            setPaintMode(option)
            setShowMenu(false)
            setSelectedOption(null)

        }
        else if (dropdownOptions === CALENDAR_MODE_STATES) {
            setCalendarMode(option)
            setShowMenu(false)
            setSelectedOption(null)
        }

    }

    const calculateWeekendDayOptions = () => {
        const newDict = {}
        Object.entries(DAYS_OF_WEEK).forEach(([key, value]) => {
            newDict[key] =  {
                key: `weekend-days-${key.toLowerCase()}`,
                icon : <SwitchButton dayNumber={value}/>,
                title : capitalizeFirstLetter(key)
            }
        })
        return newDict
    }

    const closeDropdown = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowMenu(false);
            setSelectedOption(null)
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', closeDropdown);

        return () => {
            document.removeEventListener('mousedown', closeDropdown);
        };
    }, []);

    useEffect(() => {
        if (selectedOption) setShowMenu(true)
        else setShowMenu(false)
    }, [selectedOption]);

    return (
        <div className={"advanced-mode"} ref={dropdownRef}>
            <div className="advanced-mode-content">
                <AdvancedModeContext.Provider value={{showMenu, setShowMenu,dropdownOptions,setDropdownOptions, selectedOption, setSelectedOption}}>
                    <AdvancedModeOption config={PAINT_MODE_CONFIG} states={PAINT_MODE_STATES} dynamicBackgroundColor={true}/>
                    <AdvancedModeOption config={CALENDAR_MODE_CONFIG} states={CALENDAR_MODE_STATES}/>
                    <AdvancedModeOption config={WEEKEND_DAYS_CONFIG} states={calculateWeekendDayOptions()}/>
                    <ResetButton/>
                </AdvancedModeContext.Provider>

            </div>
            <div className={`dropdown-menu ${showMenu ? 'show' : ''}`} >
                <div className="dropdown-menu-content">
                    <div className="dropdown-menu-header">
                        <p className={"title"}>{selectedOption ? selectedOption.placeholder : "No data"}</p>
                        <img src={Info} alt=""/>
                    </div>
                    <div className="dropdown-menu-options">
                        {
                            Object.entries(dropdownOptions).map(([key, option]) => {
                                return (
                                    <div className="dropdown-menu-option" id={option.key} key={key} onClick={() => handleDropdownOptionClick(option.value)}>
                                        <div className="dropdown-menu-option-content">
                                            {option.icon}
                                            <p>{option.title}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


export default memo(AdvancedMode);