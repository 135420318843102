import * as React from "react";
import '../../styles/components/switch-button.css'
import {useContext} from "react";
import {ButtonsSectionContext} from "../../context/ButtonsSectionContext";


const SwitchButton = ({dayNumber}) => {

    const {setSelectedWeekendDays, selectedWeekendDays} = useContext(ButtonsSectionContext)

    const handleDayChange = (day) => {
        setSelectedWeekendDays((prevSelected) =>
            prevSelected.includes(day)
                ? prevSelected.filter((d) => d !== day)
                : [...prevSelected, day]
        );
    };


    return (
        <div className="switch-button">
            <label className="switch-button-label"></label>
            <input type="checkbox" className="switch-button-input"
                   onChange={() => handleDayChange(dayNumber)}
                   checked={selectedWeekendDays.includes(dayNumber)}/>
        </div>
    )
}

export default SwitchButton;