import '../../../styles/components/search-bar/search-bar.css'
import * as React from "react";
import {Countries} from "../../../data/Countries";
import OptimizeButton from "./OptimizeButton";
import NumberField from "./SearchBarField/NumberField/NumberField";
import SearchBarField from "./SearchBarField/SearchBarField";
import Dropdown from "./SearchBarField/DropDown/Dropdown";
import {isEmpty} from "../../../utils/ToolFunctions"
import DatesField from "./SearchBarField/DatesField/DatesField";
import {memo, useContext} from "react";
import {SearchBarContext} from "../../../context/SearchBarContext";
const SearchBar = ({handlePost}) => {
    const {country, setCountry, region, setRegion} = useContext(SearchBarContext)
    const regions = country && Countries[country]["Subdivisions"]

    return (
        <div className="search-bar-section">
            <div className="search-bar-fields">
                <SearchBarField className={"number-field"}>
                    <NumberField title={"Días de vacaciones"} placeholder={"Añadir Días"}/>
                </SearchBarField>
                <SearchBarField className={"dates-field"}>
                    <DatesField/>
                </SearchBarField>
                <SearchBarField className={"dropdowns-field"}>
                    <Dropdown title={"País"} setFunction={setCountry} placeholder={"Selecciona el país"}
                              options={Countries} value={country}
                    />
                    <span className="divisor"></span>
                    <Dropdown title={"Region"} setFunction={setRegion}
                              placeholder={(country && !isEmpty(regions)) ? "Selecciona la region" : "No hay regiones"}
                              options={country && regions} value={region}
                    />
                </SearchBarField>
            </div>
            <OptimizeButton handlePost={handlePost}/>
        </div>
    )
}

export default memo(SearchBar);