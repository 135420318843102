import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import SearchBar from "./TopSection/SearchBar/SearchBar";
import Titles from "./Titles";
import {SearchBarContext} from "../context/SearchBarContext";
import {ButtonsSectionContext} from "../context/ButtonsSectionContext";
import AdvancedMode from "./AdvanceMode/AdvancedMode";
import {addMonths, format, isBefore, startOfMonth, startOfYear} from "date-fns";
import CalendarMonth from "./CalendarSection/CalendarMonth";
import {intersection} from "../utils/ToolFunctions";


function Home(){

    const today = new Date()
    //const [holidays, setHolidays] = useState(monthsTemplate);
    const [maxMonth] = useState(new Date(2025, 11, 31))

    // SEARCH SECTION
    const [country, setCountry] = useState("")
    const [region, setRegion] = useState("")
    const [setTotalDays] = useState(0)
    const [startDate, setStartDate] = useState(today)
    // TODO cambiar endDate:
    const [endDate, setEndDate] = useState(new Date(today.getFullYear(), today.getMonth() + 1, today.getDate()))

    // BUTTONS MODES
    const [paintMode, setPaintMode] = useState(null)
    const [reset, setReset] = useState(false)
    const [calendarMode, setCalendarMode] = useState(null)
    const [selectedWeekendDays, setSelectedWeekendDays] = useState([6, 0]);

    //CALENDAR
    const [monthsToPaint, setMonthsToPaint] = useState(getMonthsInRange(startDate, endDate))
    const [monthsValues, setMonthsValues] = useState([])
    console.log(monthsValues)

    const changeValue = useCallback((id,value) => {
        setMonthsValues((prev) => prev.map((item) => {
            if (item.id === id) item.value = value
            return item
        }))
    }, [])

    function getMonthsInRange(startDate, endDate){
        if (!startDate || !endDate) return []

        let result = [];
        let currentDate = startOfMonth(startDate);

        while (isBefore(currentDate, endDate) || currentDate.getTime() === endDate.getTime()) {
            result.push(currentDate);
            currentDate = addMonths(currentDate, 1);  // Avanza al primer día del siguiente mes
        }
        return result;
    }

    const checkMonthHasPassed = (month) => month >= startOfMonth(new Date())

    useEffect(() => {
        //Get holidays
        if (country !== "") {
            // TODO Pedir al backend el máximo mes del que se registren fiestas
            // getMaxMonth(() => setMaxMonth())
            // getHolidays(Countries[country].Code, region, setHolidays)
        }
    }, [country, region]);

    useEffect(() => {
        // Change region to "" when the country changes
        setRegion("")
    }, [country]);

    useEffect(() => {
        // TODO va a haber un error cuandos e pinten los meses pasados
        const newMonthsValues = []
        monthsToPaint.forEach((month) => {
            newMonthsValues.push({id: `${format(month, "MMMM")} ${month.getFullYear()}`, value: {}})
        })
        setMonthsValues((prevMonthsValues) => {
            return intersection(prevMonthsValues, newMonthsValues);
        });
    }, [monthsToPaint]);

    useEffect(() => {
        // Calendar view mode
        if (calendarMode === 1) setMonthsToPaint(getMonthsInRange(startOfYear(new Date()), endDate))
        else setMonthsToPaint(getMonthsInRange(startDate, endDate))
    }, [calendarMode, endDate, startDate]);


    const searchBarContextValue = useMemo(() => ({setTotalDays, country, setCountry, region, setRegion, startDate, setStartDate, endDate, setEndDate, maxMonth
    }), [country, region, startDate, endDate, maxMonth, setTotalDays]);

    const buttonsSectionContextValue = useMemo(() => ({paintMode, setPaintMode, reset, setReset, selectedWeekendDays, endDate
    }), [paintMode, reset, selectedWeekendDays, endDate]);

    const advancedModeContextValue = useMemo(() => ({paintMode, setPaintMode, reset, setReset, calendarMode, setCalendarMode, selectedWeekendDays, setSelectedWeekendDays
    }), [paintMode, reset, calendarMode, selectedWeekendDays]);

    return (
        <div className="wrapper">
            <section className="top">
                <Titles/>
                <SearchBarContext.Provider value={searchBarContextValue}>
                    <SearchBar/>
                </SearchBarContext.Provider>
            </section>
            <section className="calendar">
                <ButtonsSectionContext.Provider value={buttonsSectionContextValue}>
                    {monthsToPaint.map((month) => {
                        if (!calendarMode && !checkMonthHasPassed(month)) return false
                        else return <CalendarMonth key={month} month={month} changeValue={changeValue}/>
                    })}
                </ButtonsSectionContext.Provider>
            </section>
            <ButtonsSectionContext.Provider value={advancedModeContextValue}>
                <AdvancedMode/>
            </ButtonsSectionContext.Provider>
        </div>
    );
}


export default Home