import {
    format,
    getDaysInMonth,
    isToday,
    startOfMonth, startOfToday,
} from "date-fns";
import {useState, memo, useEffect, useContext} from "react";
import {mod} from "../../utils/ToolFunctions";
import {MONTHS, PAINT_MODE_STATES, WEEKDAYS} from "../../utils/Constants";
import {ButtonsSectionContext} from "../../context/ButtonsSectionContext";




const CalendarMonth = ({month, holidays = [], changeValue}) => {
    console.log("CALENDAR RENDER: ", month)
    const {paintMode, setPaintMode, reset, setReset, selectedWeekendDays, endDate} = useContext(ButtonsSectionContext)

    const [dayDict, setDayDict] = useState({})
    const hasPassed = (day) => day <= new Date() || day > endDate
    const isHoliday = (day) => holidays.includes(day.getDate()) || (dayDict[day.getDate()] === PAINT_MODE_STATES.HOLIDAY.value)
    const isWorkDay = (day) => dayDict[day.getDate()] === PAINT_MODE_STATES.WORK.value
    const isDayOff = (day) => dayDict[day.getDate()] === PAINT_MODE_STATES.DAY_OFF.value
    const isMyWeekend = (day) => dayDict[day.getDate()] === PAINT_MODE_STATES.WEEKEND.value

    const handleClick = (day) => {

        const newDict = {...dayDict}
        const dayNumber = day.getDate()
        const mode = Object.values(PAINT_MODE_STATES).find(state => state.value === paintMode);
        if (mode && day > startOfToday()) {
            if (mode.value === PAINT_MODE_STATES.BASIC.value) delete newDict[dayNumber]
            else if (newDict[dayNumber] === mode.value) delete newDict[dayNumber];
            else newDict[dayNumber] = mode.value;

            setDayDict(newDict)

            const id = `${format(month, "MMMM")} ${month.getFullYear()}`
            changeValue(id, newDict)
        }
    }

    const dateStyle = (day) => {

        if (isHoliday(day) && hasPassed(day)) return 'holiday-passed'
        else if (isToday(day)) return 'today'
        else if (hasPassed(day)) return 'passed'
        else if (isWorkDay(day)) return 'work'
        else if (isHoliday(day)) return 'holiday'
        else if (isMyWeekend(day)) return 'weekend'
        else if (isDayOff(day)) return 'day-off'
        else return 'basic'
    }

    useEffect(() => {
        if (reset){
            const newDict = {}
            setDayDict(newDict)
            setPaintMode(null)
            setReset(false)
        }
    }, [reset, setPaintMode, setReset]);

    useEffect(() => {
        const days = getDaysInMonth(month);
        setDayDict(prevDayDict => {
            const newDict = { ...prevDayDict }; // Copia el estado anterior

            // Empty the dict of WEEKEND entries
            Object.entries(newDict).forEach(([key, value]) =>
                (value === PAINT_MODE_STATES.WEEKEND.value) && delete newDict[key])

            for (let day = 1; day <= days; day++) {
                const dayOfWeek = new Date(month.getFullYear(), month.getMonth(), day).getDay();

                // Si el día está en los fines de semana seleccionados
                if (selectedWeekendDays.includes(dayOfWeek) && !newDict[day]) {
                    // TODO Cuando se cambia "weekdays" se borra los fines de semana pintados por el usuario
                    newDict[day] = PAINT_MODE_STATES.WEEKEND.value;
                }
            }
            return newDict; // Devuelve el nuevo estado
        });

    }, [month, selectedWeekendDays ,reset]);

    const renderMonth = () => {

        const days = getDaysInMonth(month);
        const firstDay = mod(startOfMonth(month).getDay() - 1, 7);
        const calendarDays = [];

        for (let i = 0; i < firstDay; i++) {
            calendarDays.push(<div key={`empty-${i}-${month}`} className="calendar-day empty"></div>);
        }

        for (let day = 1; day <= days; day++) {
            const date = new Date(month.getFullYear(), month.getMonth(), day);

            calendarDays.push(
                <div key={`${day}-${month}`}
                     onClick={() => handleClick(date)}
                     className={`calendar-day ${dateStyle(date)} paint-mode-${paintMode || 'default'}`}>
                    <p>{day}</p>
                </div>
            );
        }
        return (
            <div className="month-calendar">
                <div className="month-header">
                    {MONTHS[month.getMonth()]} {month.getFullYear()}
                </div>
                <div className="calendar-grid">
                    {WEEKDAYS.map((day) => <div key={day} className="calendar-weekday">{day[0]}</div>)}
                    {calendarDays}
                </div>

            </div>
        );
    }
    return (
        <div className="calendar-child">
            {renderMonth()}
        </div>

    );
}

export default memo(CalendarMonth)