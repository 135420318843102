import React, {useContext, useState} from "react";
import {ButtonsSectionContext} from "../../context/ButtonsSectionContext";
import {AdvancedModeContext} from "../../context/AdvancedModeContext";


const AdvancedModeOption = ({config, states, dynamicBackgroundColor = false}) => {

    const {paintMode} = useContext(ButtonsSectionContext)
    const {showMenu, selectedOption, setSelectedOption, setDropdownOptions} = useContext(AdvancedModeContext)

    const OptionIcon = config.icon

    const [hoverInfo, setHoverInfo] = useState(null)

    const handleClick = (options) => {

        // Second click on button
        if (selectedOption === config) setSelectedOption(null)
        else{
            // First click, setDropdownMenu options, setSelectedOption to button config
            setSelectedOption(config)
            setDropdownOptions(options)
        }
    }

    const handleClassName = () => {

        if (dynamicBackgroundColor){
            if (paintMode) return paintMode
        }
        if (selectedOption && config.key === selectedOption.key) return 'clicked'
        return 'default'
    }

    return (
        <div className="option">
            <OptionIcon className={`advanced-mode-icon ${handleClassName()}`}
                        onClick={() => handleClick(states)}
                        onMouseEnter={() => setHoverInfo('show')}
                        onMouseLeave={() => setHoverInfo(null)}
            />
            <div className={`hover-info ${(hoverInfo && !showMenu) ? hoverInfo : ''}`}>
                <p className="title">{config.placeholder}</p>
            </div>
        </div>
    )
}

export default AdvancedModeOption;