import * as React from "react";
import '../../styles/components/circle-button.css'
import { ReactComponent as Reload} from "../../static/icons/reload.svg";
import {useContext, useState} from "react";
import {ButtonsSectionContext} from "../../context/ButtonsSectionContext";
import {AdvancedModeContext} from "../../context/AdvancedModeContext";


const ResetButton = () => {

    const animationTime = 0.3 //ms
    const {setReset} = useContext(ButtonsSectionContext)
    const {showMenu, setShowMenu} = useContext(AdvancedModeContext)
    const [isRotated, setIsRotated] = useState(false)
    const [hoverInfo, setHoverInfo] = useState(null)

    const handleClick = () => {
        if (!isRotated) {
            setIsRotated(true);
            setTimeout(() => {
                setIsRotated(false);
            }, animationTime*1000);
        }
        setShowMenu(false)
        setReset(true)
    }

    return (
        <div className="option">
            <Reload className={`advanced-mode-icon default`}
                    style={isRotated ? {animation: `rotate ${animationTime}s ease-in-out forwards`} : {}}
                    onClick={handleClick}
                    onMouseEnter={() => setHoverInfo('show')}
                    onMouseLeave={() => setHoverInfo(null)}
            />
            <div className={`hover-info ${(hoverInfo && !showMenu) ? hoverInfo : ''}`}>
                <p className={"title"}>Reset</p>
            </div>
        </div>
    )

}

export default ResetButton;