


export const Countries  =
    {
            'albania': {'Code': 'AL', 'Subdivisions': {}},
            'algeria': {'Code': 'DZ', 'Subdivisions': {}},
            'american_samoa': {'Code': 'AS', 'Subdivisions': {}},
            'andorra': {
                    'Code': 'AD',
                    'Subdivisions': {
                            'Canillo': '02',
                            'Encamp': '03',
                            'La Massana': '04',
                            'Ordino': '05',
                            'Sant Julià de Lòria': '06',
                            'Andorra la Vella': '07',
                            'Escaldes-Engordany': '08'
                    }
            },
            'angola': {'Code': 'AO', 'Subdivisions': {}},
            'argentina': {'Code': 'AR', 'Subdivisions': {}},
            'armenia': {'Code': 'AM', 'Subdivisions': {}},
            'aruba': {'Code': 'AW', 'Subdivisions': {}},
            'australia': {
                    'Code': 'AU',
                    'Subdivisions': {
                            'Australian Capital Territory': 'ACT',
                            'New South Wales': 'NSW',
                            'Northern Territory': 'NT',
                            'Queensland': 'QLD',
                            'South Australia': 'SA',
                            'Tasmania': 'TAS',
                            'Victoria': 'VIC',
                            'Western Australia': 'WA'
                    }
            },
            'austria': {
                    'Code': 'AT',
                    'Subdivisions': {
                            'Burgenland': '1',
                            'Kärnten': '2',
                            'Niederösterreich': '3',
                            'Oberösterreich': '4',
                            'Salzburg': '5',
                            'Steiermark': '6',
                            'Tirol': '7',
                            'Vorarlberg': '8',
                            'Wien': '9'
                    }
            },
            'azerbaijan': {'Code': 'AZ', 'Subdivisions': {}},
            'bahamas': {'Code': 'BS', 'Subdivisions': {}},
            'bahrain': {'Code': 'BH', 'Subdivisions': {}},
            'bangladesh': {'Code': 'BD', 'Subdivisions': {}},
            'barbados': {'Code': 'BB', 'Subdivisions': {}},
            'belarus': {'Code': 'BY', 'Subdivisions': {}},
            'belgium': {'Code': 'BE', 'Subdivisions': {}},
            'belize': {'Code': 'BZ', 'Subdivisions': {}},
            'bolivia': {
                    'Code': 'BO',
                    'Subdivisions': {
                            'El Beni': 'B',
                            'Cochabamba': 'C',
                            'Chuquisaca': 'H',
                            'La Paz': 'L',
                            'Pando': 'N',
                            'Oruro': 'O',
                            'Potosí': 'P',
                            'Santa Cruz': 'S',
                            'Tarija': 'T'
                    }
            },
            'bosnia_and_herzegovina': {
                    'Code': 'BA',
                    'Subdivisions': {
                            'Federacija Bosne i Hercegovine': 'BIH',
                            'Brčko distrikt': 'BRC',
                            'Republika Srpska': 'SRP'
                    }
            },
            'botswana': {'Code': 'BW', 'Subdivisions': {}},
            'brazil': {
                    'Code': 'BR',
                    'Subdivisions': {
                            'Acre': 'AC',
                            'Alagoas': 'AL',
                            'Amazonas': 'AM',
                            'Amapá': 'AP',
                            'Bahia': 'BA',
                            'Ceará': 'CE',
                            'Distrito Federal': 'DF',
                            'Espírito Santo': 'ES',
                            'Goiás': 'GO',
                            'Maranhão': 'MA',
                            'Minas Gerais': 'MG',
                            'Mato Grosso do Sul': 'MS',
                            'Mato Grosso': 'MT',
                            'Pará': 'PA',
                            'Paraíba': 'PB',
                            'Pernambuco': 'PE',
                            'Piauí': 'PI',
                            'Paraná': 'PR',
                            'Rio de Janeiro': 'RJ',
                            'Rio Grande do Norte': 'RN',
                            'Rondônia': 'RO',
                            'Roraima': 'RR',
                            'Rio Grande do Sul': 'RS',
                            'Santa Catarina': 'SC',
                            'Sergipe': 'SE',
                            'São Paulo': 'SP',
                            'Tocantins': 'TO'
                    }
            },
            'brunei': {'Code': 'BN', 'Subdivisions': {}},
            'bulgaria': {'Code': 'BG', 'Subdivisions': {}},
            'burkina_faso': {'Code': 'BF', 'Subdivisions': {}},
            'burundi': {'Code': 'BI', 'Subdivisions': {}},
            'cambodia': {'Code': 'KH', 'Subdivisions': {}},
            'cameroon': {'Code': 'CM', 'Subdivisions': {}},
            'canada': {
                    'Code': 'CA',
                    'Subdivisions': {
                            'Alberta': 'AB',
                            'British Columbia': 'BC',
                            'Manitoba': 'MB',
                            'New Brunswick': 'NB',
                            'Newfoundland and Labrador': 'NL',
                            'Nova Scotia': 'NS',
                            'Northwest Territories': 'NT',
                            'Nunavut': 'NU',
                            'Ontario': 'ON',
                            'Prince Edward Island': 'PE',
                            'Quebec': 'QC',
                            'Saskatchewan': 'SK',
                            'Yukon': 'YT'
                    }
            },
            'chad': {'Code': 'TD', 'Subdivisions': {}},
            'chile': {
                    'Code': 'CL',
                    'Subdivisions': {
                            'Aisén del General Carlos Ibañez del Campo': 'AI',
                            'Antofagasta': 'AN',
                            'Arica y Parinacota': 'AP',
                            'La Araucanía': 'AR',
                            'Atacama': 'AT',
                            'Biobío': 'BI',
                            'Coquimbo': 'CO',
                            "Libertador General Bernardo O'Higgins": 'LI',
                            'Los Lagos': 'LL',
                            'Los Ríos': 'LR',
                            'Magallanes': 'MA',
                            'Maule': 'ML',
                            'Ñuble': 'NB',
                            'Región Metropolitana de Santiago': 'RM',
                            'Tarapacá': 'TA',
                            'Valparaíso': 'VS'
                    }
            },
            'china': {'Code': 'CN', 'Subdivisions': {}},
            'colombia': {'Code': 'CO', 'Subdivisions': {}},
            'costa_rica': {'Code': 'CR', 'Subdivisions': {}},
            'croatia': {'Code': 'HR', 'Subdivisions': {}},
            'cuba': {'Code': 'CU', 'Subdivisions': {}},
            'curacao': {'Code': 'CW', 'Subdivisions': {}},
            'cyprus': {'Code': 'CY', 'Subdivisions': {}},
            'czechia': {'Code': 'CZ', 'Subdivisions': {}},
            'denmark': {'Code': 'DK', 'Subdivisions': {}},
            'djibouti': {'Code': 'DJ', 'Subdivisions': {}},
            'dominican_republic': {'Code': 'DO', 'Subdivisions': {}},
            'ecuador': {'Code': 'EC', 'Subdivisions': {}},
            'egypt': {'Code': 'EG', 'Subdivisions': {}},
            'el_salvador': {
                    'Code': 'SV',
                    'Subdivisions': {
                            'Ahuachapán': 'AH',
                            'Cabañas': 'CA',
                            'Chalatenango': 'CH',
                            'Cuscatlán': 'CU',
                            'La Libertad': 'LI',
                            'Morazán': 'MO',
                            'La Paz': 'PA',
                            'Santa Ana': 'SA',
                            'San Miguel': 'SM',
                            'Sonsonate': 'SO',
                            'San Salvador': 'SS',
                            'San Vicente': 'SV',
                            'La Unión': 'UN',
                            'Usulután': 'US'
                    }
            },
            'estonia': {'Code': 'EE', 'Subdivisions': {}},
            'eswatini': {'Code': 'SZ', 'Subdivisions': {}},
            'ethiopia': {'Code': 'ET', 'Subdivisions': {}},
            'finland': {'Code': 'FI', 'Subdivisions': {}},
            'france': {
                    'Code': 'FR',
                    'Subdivisions': {
                            'Saint-Barthélemy': 'BL',
                            'Grand-Est': 'GES',
                            'Guadeloupe': 'GP',
                            '': 'GY',
                            'Saint-Martin': 'MF',
                            'Martinique': 'MQ',
                            'Nouvelle-Calédonie': 'NC',
                            'Polynésie française': 'PF',
                            'La Réunion': 'RE',
                            'Wallis-et-Futuna': 'WF',
                            'Mayotte': 'YT'
                    }
            },
            'gabon': {'Code': 'GA', 'Subdivisions': {}},
            'georgia': {'Code': 'GE', 'Subdivisions': {}},
            'germany': {
                    'Code': 'DE',
                    'Subdivisions': {
                            'Brandenburg': 'BB',
                            'Berlin': 'BE',
                            'Baden-Württemberg': 'BW',
                            'Bayern': 'BY',
                            '': 'BYP',
                            'Bremen': 'HB',
                            'Hessen': 'HE',
                            'Hamburg': 'HH',
                            'Mecklenburg-Vorpommern': 'MV',
                            'Niedersachsen': 'NI',
                            'Nordrhein-Westfalen': 'NW',
                            'Rheinland-Pfalz': 'RP',
                            'Schleswig-Holstein': 'SH',
                            'Saarland': 'SL',
                            'Sachsen': 'SN',
                            'Sachsen-Anhalt': 'ST',
                            'Thüringen': 'TH'
                    }
            },
            'greece': {'Code': 'GR', 'Subdivisions': {}},
            'guam': {'Code': 'GU', 'Subdivisions': {}},
            'guatemala': {'Code': 'GT', 'Subdivisions': {}},
            'honduras': {'Code': 'HN', 'Subdivisions': {}},
            'hongkong': {'Code': 'HK', 'Subdivisions': {}},
            'hungary': {'Code': 'HU', 'Subdivisions': {}},
            'iceland': {'Code': 'IS', 'Subdivisions': {}},
            'india': {
                    'Code': 'IN',
                    'Subdivisions': {
                            'Andaman and Nicobar Islands': 'AN',
                            'Andhra Pradesh': 'AP',
                            'Arunāchal Pradesh': 'AR',
                            'Assam': 'AS',
                            'Bihār': 'BR',
                            '': 'UK',
                            'Chandīgarh': 'CH',
                            'Dādra and Nagar Haveli and Damān and Diu': 'DH',
                            'Delhi': 'DL',
                            'Goa': 'GA',
                            'Gujarāt': 'GJ',
                            'Himāchal Pradesh': 'HP',
                            'Haryāna': 'HR',
                            'Jhārkhand': 'JH',
                            'Jammu and Kashmīr': 'JK',
                            'Karnātaka': 'KA',
                            'Kerala': 'KL',
                            'Ladākh': 'LA',
                            'Lakshadweep': 'LD',
                            'Mahārāshtra': 'MH',
                            'Meghālaya': 'ML',
                            'Manipur': 'MN',
                            'Madhya Pradesh': 'MP',
                            'Mizoram': 'MZ',
                            'Nāgāland': 'NL',
                            'Odisha': 'OR',
                            'Punjab': 'PB',
                            'Puducherry': 'PY',
                            'Rājasthān': 'RJ',
                            'Sikkim': 'SK',
                            'Tamil Nādu': 'TN',
                            'Tripura': 'TR',
                            'Uttar Pradesh': 'UP',
                            'West Bengal': 'WB'
                    }
            },
            'indonesia': {'Code': 'ID', 'Subdivisions': {}},
            'iran': {'Code': 'IR', 'Subdivisions': {}},
            'ireland': {'Code': 'IE', 'Subdivisions': {}},
            'isle_of_man': {'Code': 'IM', 'Subdivisions': {}},
            'israel': {'Code': 'IL', 'Subdivisions': {}},
            'italy': {
                    'Code': 'IT', 'Subdivisions': {
                            'Agrigento': 'AG',
                            'Alessandria': 'AL',
                            'Ancona': 'AN',
                            '': 'Urbino',
                            'Ascoli Piceno': 'AP',
                            "L'Aquila": 'AQ',
                            'Arezzo': 'AR',
                            'Asti': 'AT',
                            'Avellino': 'AV',
                            'Bari': 'BA',
                            'Bergamo': 'BG',
                            'Biella': 'BI',
                            'Belluno': 'BL',
                            'Benevento': 'BN',
                            'Bologna': 'BO',
                            'Brindisi': 'BR',
                            'Brescia': 'BS',
                            'Barletta-Andria-Trani': 'BT',
                            'Bolzano': 'BZ',
                            'Cagliari': 'CA',
                            'Campobasso': 'CB',
                            'Caserta': 'CE',
                            'Chieti': 'CH',
                            'Caltanissetta': 'CL',
                            'Cuneo': 'CN',
                            'Como': 'CO',
                            'Cremona': 'CR',
                            'Cosenza': 'CS',
                            'Catania': 'CT',
                            'Catanzaro': 'CZ',
                            'Enna': 'EN',
                            'Forlì-Cesena': 'FC',
                            'Ferrara': 'FE',
                            'Foggia': 'FG',
                            'Firenze': 'FI',
                            'Fermo': 'FM',
                            'Frosinone': 'FR',
                            'Genova': 'GE',
                            'Gorizia': 'GO',
                            'Grosseto': 'GR',
                            'Imperia': 'IM',
                            'Isernia': 'IS',
                            'Crotone': 'KR',
                            'Lecco': 'LC',
                            'Lecce': 'LE',
                            'Livorno': 'LI',
                            'Lodi': 'LO',
                            'Latina': 'LT',
                            'Lucca': 'LU',
                            'Monza e Brianza': 'MB',
                            'Macerata': 'MC',
                            'Messina': 'ME',
                            'Milano': 'MI',
                            'Mantova': 'MN',
                            'Modena': 'MO',
                            'Massa-Carrara': 'MS',
                            'Matera': 'MT',
                            'Napoli': 'NA',
                            'Novara': 'NO',
                            'Nuoro': 'NU',
                            'Oristano': 'OR',
                            'Palermo': 'PA',
                            'Piacenza': 'PC',
                            'Padova': 'PD',
                            'Pescara': 'PE',
                            'Perugia': 'PG',
                            'Pisa': 'PI',
                            'Pordenone': 'PN',
                            'Prato': 'PO',
                            'Parma': 'PR',
                            'Pistoia': 'PT',
                            'Pesaro e Urbino': 'PU',
                            'Pavia': 'PV',
                            'Potenza': 'PZ',
                            'Ravenna': 'RA',
                            'Reggio Calabria': 'RC',
                            'Reggio Emilia': 'RE',
                            'Ragusa': 'RG',
                            'Rieti': 'RI',
                            'Roma': 'RM',
                            'Rimini': 'RN',
                            'Rovigo': 'RO',
                            'Salerno': 'SA',
                            'Siena': 'SI',
                            'Sondrio': 'SO',
                            'La Spezia': 'SP',
                            'Siracusa': 'SR',
                            'Sassari': 'SS',
                            'Sud Sardegna': 'SU',
                            'Savona': 'SV',
                            'Taranto': 'TA',
                            'Teramo': 'TE',
                            'Trento': 'TN',
                            'Torino': 'TO',
                            'Trapani': 'TP',
                            'Terni': 'TR',
                            'Trieste': 'TS',
                            'Treviso': 'TV',
                            'Udine': 'UD',
                            'Varese': 'VA',
                            'Verbano-Cusio-Ossola': 'VB',
                            'Vercelli': 'VC',
                            'Venezia': 'VE',
                            'Vicenza': 'VI',
                            'Verona': 'VR',
                            'Viterbo': 'VT',
                            'Vibo Valentia': 'VV'
                    }
            },
            'jamaica': {'Code': 'JM', 'Subdivisions': {}},
            'japan': {'Code': 'JP', 'Subdivisions': {}},
            'kazakhstan': {'Code': 'KZ', 'Subdivisions': {}},
            'kenya': {'Code': 'KE', 'Subdivisions': {}},
            'kyrgyzstan': {'Code': 'KG', 'Subdivisions': {}},
            'laos': {'Code': 'LA', 'Subdivisions': {}},
            'latvia': {'Code': 'LV', 'Subdivisions': {}},
            'lesotho': {'Code': 'LS', 'Subdivisions': {}},
            'liechtenstein': {'Code': 'LI', 'Subdivisions': {}},
            'lithuania': {'Code': 'LT', 'Subdivisions': {}},
            'luxembourg': {'Code': 'LU', 'Subdivisions': {}},
            'madagascar': {'Code': 'MG', 'Subdivisions': {}},
            'malawi': {'Code': 'MW', 'Subdivisions': {}},
            'malaysia': {'Code': 'MY', 'Subdivisions': {'': 'TRG'}},
            'maldives': {'Code': 'MV', 'Subdivisions': {}},
            'malta': {'Code': 'MT', 'Subdivisions': {}},
            'marshall_islands': {'Code': 'MH', 'Subdivisions': {}},
            'mexico': {'Code': 'MX', 'Subdivisions': {}},
            'moldova': {'Code': 'MD', 'Subdivisions': {}},
            'monaco': {'Code': 'MC', 'Subdivisions': {}},
            'montenegro': {'Code': 'ME', 'Subdivisions': {}},
            'morocco': {'Code': 'MA', 'Subdivisions': {}},
            'mozambique': {'Code': 'MZ', 'Subdivisions': {}},
            'namibia': {'Code': 'NA', 'Subdivisions': {}},
            'netherlands': {'Code': 'NL', 'Subdivisions': {}},
            'new_zealand': {
                    'Code': 'NZ',
                    'Subdivisions': {
                            'Auckland': 'AUK',
                            'Bay of Plenty': 'BOP',
                            'Canterbury': 'CAN',
                            'Chatham Islands Territory': 'CIT',
                            'Gisborne': 'GIS',
                            "Hawke's Bay": 'HKB',
                            'Marlborough': 'MBH',
                            'Manawatu-Wanganui': 'MWT',
                            'Nelson': 'NSN',
                            'Northland': 'NTL',
                            'Otago': 'OTA',
                            'Southland': 'STL',
                            'Tasman': 'TAS',
                            'Taranaki': 'TKI',
                            'Wellington': 'WGN',
                            'Waikato': 'WKO',
                            'West Coast': 'WTC'
                    }
            },
            'nicaragua': {
                    'Code': 'NI',
                    'Subdivisions': {
                            'Costa Caribe Norte': 'AN',
                            'Costa Caribe Sur': 'AS',
                            'Boaco': 'BO',
                            'Carazo': 'CA',
                            'Chinandega': 'CI',
                            'Chontales': 'CO',
                            'Estelí': 'ES',
                            'Granada': 'GR',
                            'Jinotega': 'JI',
                            'León': 'LE',
                            'Madriz': 'MD',
                            'Managua': 'MN',
                            'Masaya': 'MS',
                            'Matagalpa': 'MT',
                            'Nueva Segovia': 'NS',
                            'Rivas': 'RI',
                            'Río San Juan': 'SJ'
                    }
            },
            'nigeria': {'Code': 'NG', 'Subdivisions': {}},
            'north_macedonia': {'Code': 'MK', 'Subdivisions': {}},
            'northern_mariana_islands': {'Code': 'MP', 'Subdivisions': {}},
            'norway': {'Code': 'NO', 'Subdivisions': {}},
            'pakistan': {'Code': 'PK', 'Subdivisions': {}},
            'panama': {'Code': 'PA', 'Subdivisions': {}},
            'papua_new_guinea': {'Code': 'PG', 'Subdivisions': {}},
            'paraguay': {'Code': 'PY', 'Subdivisions': {}},
            'peru': {'Code': 'PE', 'Subdivisions': {}},
            'philippines': {'Code': 'PH', 'Subdivisions': {}},
            'poland': {'Code': 'PL', 'Subdivisions': {}},
            'portugal': {
                    'Code': 'PT',
                    'Subdivisions': {
                            'Aveiro': '01',
                            'Beja': '02',
                            'Braga': '03',
                            'Bragança': '04',
                            'Castelo Branco': '05',
                            'Coimbra': '06',
                            'Évora': '07',
                            'Faro': '08',
                            'Guarda': '09',
                            'Leiria': '10',
                            'Lisboa': '11',
                            'Portalegre': '12',
                            'Porto': '13',
                            'Santarém': '14',
                            'Setúbal': '15',
                            'Viana do Castelo': '16',
                            'Vila Real': '17',
                            'Viseu': '18',
                            'Região Autónoma dos Açores': '20',
                            'Região Autónoma da Madeira': '30'
                    }
            },
            'puerto_rico': {'Code': 'PR', 'Subdivisions': {}},
            'romania': {'Code': 'RO', 'Subdivisions': {}},
            'russia': {'Code': 'RU', 'Subdivisions': {}},
            'san_marino': {'Code': 'SM', 'Subdivisions': {}},
            'saudi_arabia': {'Code': 'SA', 'Subdivisions': {}},
            'serbia': {'Code': 'RS', 'Subdivisions': {}},
            'singapore': {'Code': 'SG', 'Subdivisions': {}},
            'slovakia': {'Code': 'SK', 'Subdivisions': {}},
            'slovenia': {'Code': 'SI', 'Subdivisions': {}},
            'south_africa': {'Code': 'ZA', 'Subdivisions': {}},
            'south_korea': {'Code': 'KR', 'Subdivisions': {}},
            'spain': {
                    'Code': 'ES',
                    'Subdivisions': {
                            'Andalucía': 'AN',
                            'Aragón': 'AR',
                            'Asturias, Principado de': 'AS',
                            'Cantabria': 'CB',
                            'Ceuta': 'CE',
                            'Castilla y León': 'CL',
                            'Castilla-La Mancha': 'CM',
                            'Canarias': 'CN',
                            'Catalunya [Cataluña]': 'CT',
                            'Extremadura': 'EX',
                            'Galicia [Galicia]': 'GA',
                            'Illes Balears [Islas Baleares]': 'IB',
                            'Murcia, Región de': 'MC',
                            'Madrid, Comunidad de': 'MD',
                            'Melilla': 'ML',
                            'Nafarroako Foru Komunitatea*': 'NC',
                            'Euskal Herria': 'PV',
                            'La Rioja': 'RI',
                            'Valenciana, Comunidad': 'VC'
                    }
            },
            'sweden': {'Code': 'SE', 'Subdivisions': {}},
            'switzerland': {
                    'Code': 'CH',
                    'Subdivisions': {
                            'Aargau': 'AG',
                            'Appenzell Ausserrhoden': 'AR',
                            'Appenzell Innerrhoden': 'AI',
                            'Basel-Landschaft': 'BL',
                            'Basel-Stadt': 'BS',
                            'Bern': 'BE',
                            'Freiburg': 'FR',
                            'Genève': 'GE',
                            'Glarus': 'GL',
                            'Graubünden': 'GR',
                            'Jura': 'JU',
                            'Luzern': 'LU',
                            'Neuchâtel': 'NE',
                            'Nidwalden': 'NW',
                            'Obwalden': 'OW',
                            'Sankt Gallen': 'SG',
                            'Schaffhausen': 'SH',
                            'Schwyz': 'SZ',
                            'Solothurn': 'SO',
                            'Thurgau': 'TG',
                            'Ticino': 'TI',
                            'Uri': 'UR',
                            'Vaud': 'VD',
                            'Valais': 'VS',
                            'Zug': 'ZG',
                            'Zürich': 'ZH'
                    }
            },
            'taiwan': {'Code': 'TW', 'Subdivisions': {}},
            'tanzania': {'Code': 'TZ', 'Subdivisions': {}},
            'thailand': {'Code': 'TH', 'Subdivisions': {}},
            'timor_leste': {'Code': 'TL', 'Subdivisions': {}},
            'tonga': {'Code': 'TO', 'Subdivisions': {}},
            'tunisia': {'Code': 'TN', 'Subdivisions': {}},
            'turkey': {'Code': 'TR', 'Subdivisions': {}},
            'ukraine': {'Code': 'UA', 'Subdivisions': {}},
            'united_arab_emirates': {'Code': 'AE', 'Subdivisions': {}},
            'united_kingdom': {
                    'Code': 'GB',
                    'Subdivisions': {
                            'England': 'ENG',
                            'Northern Ireland': 'NIR',
                            'Scotland': 'SCT',
                            'Wales [Cymru GB-CYM]': 'WLS'
                    }
            },
            'united_states_minor_outlying_islands': {'Code': 'UM', 'Subdivisions': {}},
            'united_states_virgin_islands': {'Code': 'VI', 'Subdivisions': {}},
            'united_states': {
                    'Code': 'US', 'Subdivisions': {
                            'Alaska': 'AK',
                            'Alabama': 'AL',
                            'Arkansas': 'AR',
                            'American Samoa': 'AS',
                            'Arizona': 'AZ',
                            'California': 'CA',
                            'Colorado': 'CO',
                            'Connecticut': 'CT',
                            'District of Columbia': 'DC',
                            'Delaware': 'DE',
                            'Florida': 'FL',
                            'Georgia': 'GA',
                            'Guam': 'GU',
                            'Hawaii': 'HI',
                            'Iowa': 'IA',
                            'Idaho': 'ID',
                            'Illinois': 'IL',
                            'Indiana': 'IN',
                            'Kansas': 'KS',
                            'Kentucky': 'KY',
                            'Louisiana': 'LA',
                            'Massachusetts': 'MA',
                            'Maryland': 'MD',
                            'Maine': 'ME',
                            'Michigan': 'MI',
                            'Minnesota': 'MN',
                            'Missouri': 'MO',
                            'Northern Mariana Islands': 'MP',
                            'Mississippi': 'MS',
                            'Montana': 'MT',
                            'North Carolina': 'NC',
                            'North Dakota': 'ND',
                            'Nebraska': 'NE',
                            'New Hampshire': 'NH',
                            'New Jersey': 'NJ',
                            'New Mexico': 'NM',
                            'Nevada': 'NV',
                            'New York': 'NY',
                            'Ohio': 'OH',
                            'Oklahoma': 'OK',
                            'Oregon': 'OR',
                            'Pennsylvania': 'PA',
                            'Puerto Rico': 'PR',
                            'Rhode Island': 'RI',
                            'South Carolina': 'SC',
                            'South Dakota': 'SD',
                            'Tennessee': 'TN',
                            'Texas': 'TX',
                            'United States Minor Outlying Islands': 'UM',
                            'Utah': 'UT',
                            'Virginia': 'VA',
                            'Virgin Islands, U.S.': 'VI',
                            'Vermont': 'VT',
                            'Washington': 'WA',
                            'Wisconsin': 'WI',
                            'West Virginia': 'WV',
                            'Wyoming': 'WY'
                    }
            },
            'uruguay': {'Code': 'UY', 'Subdivisions': {}},
            'uzbekistan': {'Code': 'UZ', 'Subdivisions': {}},
            'vanuatu': {'Code': 'VU', 'Subdivisions': {}},
            'vatican_city': {'Code': 'VA', 'Subdivisions': {}},
            'venezuela': {'Code': 'VE', 'Subdivisions': {}},
            'vietnam': {'Code': 'VN', 'Subdivisions': {}},
            'zambia': {'Code': 'ZM', 'Subdivisions': {}},
            'zimbabwe': {'Code': 'ZW', 'Subdivisions': {}}
    }
