import {useEffect, useRef, useState} from "react";

const NumberField = ({title, placeholder}) => {
    // TODO actualizar el valor del "Home", totalDays
    const [value, setValue] = useState("")

    const inputRef = useRef(null)

    const [clicked, setClicked] = useState(false)
    const fieldRef = useRef(null);

    const closeDropdown = (event) => {
        if (fieldRef.current && !fieldRef.current.contains(event.target)) {
            setClicked(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', closeDropdown);

        return () => {
            document.removeEventListener('mousedown', closeDropdown);
        };
    }, []);

    const handleOnChange = (event) => {
        const newValue = event.target.value
        const numericValue = Number(newValue)
        setValue(() => {
            if (numericValue < 1) return ""
            if (numericValue > 365) return 365
            return numericValue
        })
    }

    const handleOnBlur = () => {
        setValue(value)
        setClicked(false)
    }
    const handleClick = () => {
        inputRef.current.focus()
        setClicked(true)
    }

    return (
        <div className={`search-bar-field-content ${clicked ? "clicked": ""}`} onClick={handleClick} ref={fieldRef}>
            <div className="text">
                <p className={"title"}>{title}</p>
                <input type="number"
                       className={"placeholder number-field-placeholder"}
                       value={value} placeholder={placeholder} ref={inputRef}
                       onChange={handleOnChange}
                       onBlur={handleOnBlur}
                />
            </div>
        </div>
    );
};

export default NumberField;