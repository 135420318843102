import '../../../../styles/components/search-bar/search-bar-field.css'

const SearchBarField = ({children, className}) => {

    return(
        <div className={`search-bar-field ${className || ""}`}>
        {children}
        </div>
    );
}



export default SearchBarField;