

export function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}

export function mod(number, base) {
    return ((number % base) + base) % base;
}

export function capitalizeFirstLetter(string) {
    const newString = string.toLowerCase()
    if (string.length === 0) return '';
    return newString.charAt(0).toUpperCase() + newString.slice(1);
}

export const intersection = (oldMonths, newMonths) => {
    // Convertimos oldMonths a un diccionario para búsqueda rápida
    const oldMonthsMap = {};
    oldMonths.forEach((oldItem) => {
        oldMonthsMap[oldItem.id] = oldItem.value;
    });

    // Ahora hacemos la intersección
    return newMonths.map((newItem) => {
        const oldValue = oldMonthsMap[newItem.id] || {};
        return {id: newItem.id, value: oldValue};
    });
};
