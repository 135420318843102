import * as React from "react";
import '../styles/components/titles.css'

const Titles = () => {
    return (
        <div className="titles-section">
            <h1 className="title">Optimize My Holidays</h1>
            <h3 className="description">Maximize your vacation time by using the fewest possible days off. </h3>
        </div>
    )
}

export default Titles;