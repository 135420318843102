import * as React from "react";
import "../../../../../styles/components/search-bar/dates-field.css"
import {ReactComponent as ArrowDates} from "../../../../../static/icons/arrow_dates.svg";
import {ReactComponent as CloseArrow} from "../../../../../static/icons/close.svg";
import {useContext, useEffect, useRef, useState} from "react";
import DateRangePicker from "./DateRangePicker/DateRangePicker";
import {SearchBarContext} from "../../../../../context/SearchBarContext";
import {DateFieldsContext} from "../../../../../context/DateFieldsContext";
const DatesField = () => {

    const {startDate,setStartDate,endDate, setEndDate} = useContext(SearchBarContext)

    const datePickerRef = useRef(null);

    const [clicked, setClicked] = useState({startDate: false, endDate: false})
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [showMenu, setShowMenu] = useState(false)


    const closeDatePicker = (event) => {
        if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
            setShowMenu(false)
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', closeDatePicker);

        return () => {
            document.removeEventListener('mousedown', closeDatePicker);
        };
    }, []);

    useEffect(() => {if (!showMenu) setClicked({startDate: false, endDate: false})}, [showMenu]);

    const handleDatePickerDisplayed = (isStartDate) => {

        setClicked(() => {
            if (isStartDate) return {startDate: true, endDate: false}
            else return {startDate: false, endDate: true}
        })
        const date = isStartDate ? startDate : endDate
        if (date) {
            const newMonth = new Date(date.getFullYear(), date.getMonth(), 1)
            setCurrentMonth(newMonth)
        }
        setShowMenu(true)
    }

    const handleCloseArrowVisibility = (isStartDate) =>{
        // StartDate close arrow
        if (isStartDate) {
            // If startDate is selected and user clicked on StartDate selector
            if (startDate && clicked.startDate === true) return "show"
        }else{
            // If endDate is selected and user clicked on EndDate selector
            if (endDate && clicked.endDate === true) return "show"
        }
        return ""
    }

    const resetRangeDates = () => {
        setStartDate(null)
        setEndDate(null)
    }

    return (
        <div className="search-bar-field-content dates-field-content" ref={datePickerRef}>
            <div className={`from ${clicked.startDate ? "clicked" : ""}`}
                 onClick={() => handleDatePickerDisplayed(true)}>
                <div className="text">
                    <p className={"title"}>Desde</p>
                    <p className={"placeholder"}>{startDate ? startDate.toLocaleDateString() : "Selecciona fecha"}</p>
                </div>
                <div className={`close-arrow ${handleCloseArrowVisibility(true)}`} onClick={resetRangeDates}>
                    <CloseArrow className={"icon"}/>
                </div>
            </div>
            <ArrowDates className={"arrow-dates"}/>
            <div className={`to ${clicked.endDate ? "clicked" : ""}`} onClick={() => handleDatePickerDisplayed(false)}>
                <div className="text">
                    <p className={"title"}>Hasta</p>
                    <p className={"placeholder"}>{endDate ? endDate.toLocaleDateString() : "Selecciona fecha"}</p>
                </div>
                <div className={`close-arrow ${handleCloseArrowVisibility(false)}`} onClick={resetRangeDates}>
                    <CloseArrow className={"icon"}/>
                </div>
            </div>
            <DateFieldsContext.Provider value={{setShowMenu, clicked, setClicked, currentMonth, setCurrentMonth}}>
                <DateRangePicker className={showMenu ? "show" : ""}/>
            </DateFieldsContext.Provider>
        </div>
    )
}

export default DatesField;