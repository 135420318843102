import * as React from "react";
import { ReactComponent as Elipse} from '../static/icons/elipse.svg'
import { ReactComponent as Colors } from "../static/icons/colors.svg"
import { ReactComponent as EditCalendar } from "../static/icons/edit_calendar.svg"
import {ReactComponent as WeekendDays} from '../static/icons/calendar_view_week.svg'
import {ReactComponent as AllMonths} from '../static/icons/all_months.svg'
import {ReactComponent as Upcoming} from '../static/icons/remaining_months.svg'

export const WEEKDAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
export const MONTHS = {
    0: "January",
    1: "February",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December"
};

// -- PAINT MODE BUTTON --
export const PAINT_MODE_CONFIG = {
    key: "paint_mode",
    placeholder: 'Paint Mode',
    icon: Colors,
};

// TODO Modificar el "backgroundColor" como clase
export const PAINT_MODE_STATES = {
    HOLIDAY: {
        key: "paint-mode-holiday",
        value: "holiday",
        icon: <Elipse className={"paint-mode-color holiday"}/>,
        title: "Holiday",
        backgroundColor: "#F52003",
        dark: false
    },
    DAY_OFF: {
        key: "paint-mode-day_off",
        value: "day_off",
        icon: <Elipse className={"paint-mode-color day-off"}/>,
        title: "Day Off",
        backgroundColor: "#5ADFFD",
        dark: true
    },
    WORK: {
        key: "paint-mode-work",
        value: "work",
        icon: <Elipse className={"paint-mode-color work"}/>,
        title: "Work",
        backgroundColor: "#FAFD5A",
        dark: true
    },
    WEEKEND: {
        key: "paint-mode-weekend",
        value: "weekend",
        icon: <Elipse className={"paint-mode-color weekend"}/>,
        title: "Weekend",
        backgroundColor: "#8C8C8C",
        dark: false
    },
    BASIC: {
        key: "paint-mode-basic",
        value: "basic",
        icon: <Elipse className={"paint-mode-color basic"}/>,
        title: "Basic",
        backgroundColor: "#8C8C8C",
        dark: false
    }
}

// -- CALENDAR MODE BUTTON --
export const CALENDAR_MODE_CONFIG = {
    key: "calendar_mode",
    placeholder: 'Calendar Mode',
    icon: EditCalendar,
};
export const CALENDAR_MODE_STATES = {
    UPCOMING_MONTHS: {
        key: "calendar-mode-upcoming_months",
        value: 0,
        icon: <Upcoming width={"21px"} height={"21px"}/>,
        title: "Upcoming Months",
    },
    ALL_MONTHS: {
        key: "calendar-mode-all_months",
        value: 1,
        icon: <AllMonths width={"21px"} height={"21px"}/>,
        title: "All Months",
    }
}


// -- WEEKEND DAYS BUTTON --
export const WEEKEND_DAYS_CONFIG = {
    key: "weekend_days",
    placeholder: 'Weekend Days',
    icon: WeekendDays,
};
export const DAYS_OF_WEEK = {
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
    SUNDAY: 0
};

