import '../../../../../styles/components/search-bar/dropdown.css'
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {capitalizeFirstLetter} from "../../../../../utils/ToolFunctions";
import { ReactComponent as Location} from "../../../../../static/icons/location.svg";
import {ReactComponent as CloseArrow} from "../../../../../static/icons/close.svg";


const Dropdown = ({title, placeholder, value, options, setFunction}) => {

    const [showMenu, setShowMenu] = useState(false)
    const [inputValue, setInputValue] = useState('');

    const [hoveredIndex, setHoveredIndex] = useState(-1); // -1 significa ninguna opción en hover

    const dropdownRef = useRef(null);
    const inputRef = useRef(null);
    const buttonRef = useRef(null)

    const filteredOptions = Object.entries(options).filter(([key]) =>
        key.toLowerCase().includes(inputValue.toLowerCase())
    );

    const closeDropdown = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', closeDropdown);

        return () => {
            document.removeEventListener('mousedown', closeDropdown);
        };
    }, []);

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            if (hoveredIndex === -1) {
                setShowMenu(true)
                setHoveredIndex(0)
            } else {
                const selectedValue = filteredOptions[hoveredIndex]
                if (selectedValue) setFunction(selectedValue[0])
                setHoveredIndex(-1)
                setShowMenu(false)
            }
        } else if (event.key === 'ArrowDown') {
            // Navegar hacia abajo
            setHoveredIndex((prevIndex) =>
                prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : 0
            );
        } else if (event.key === 'ArrowUp') {
            // Navegar hacia arriba
            setHoveredIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : filteredOptions.length - 1
            );
        }
    }

    const handleOnFocus = () => setShowMenu(true)


    const handleOptionClick = (key) => {
        setFunction(key)
        setShowMenu(false)
    }

    const handleDropdownDisplayed = () => {
        inputRef.current.focus()
        buttonRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        setShowMenu(true)
    }

    const handleCloseArrowVisibility = () =>{
        // StartDate close arrow
        if (value) return 'show'
        return ""
    }

    const resetLocation = () =>{
        setFunction("")
    }

    useEffect(() => {
        setInputValue(value)
    }, [value]);

    return (
        <div className={`search-bar-field-content dropdown-content ${showMenu ? "clicked" : ""}`} ref={buttonRef}>
            <div className="location-input">
                <div className="text" onClick={handleDropdownDisplayed}>
                    <p className={"title"}>{title}</p>
                    <input className={"placeholder"} type="search" ref={inputRef}
                           placeholder={placeholder}
                           value={capitalizeFirstLetter(inputValue.replaceAll("_", " "))}
                           onChange={(e) => setInputValue(e.target.value)}
                           onKeyDown={handleKeyDown}
                           onFocus={handleOnFocus}
                    />

                </div>
                <div className={`close-arrow ${handleCloseArrowVisibility(true)}`} onClick={resetLocation}>
                    <CloseArrow className={"icon"}/>
                </div>
            </div>

            <div className={`dropdown-menu ${showMenu ? 'show' : ''}`} ref={dropdownRef}>
                <div className="dropdown-menu-content">
                    <div className="dropdown-menu-options">
                        {filteredOptions.length > 0 ? (
                            filteredOptions.map(([key], index) => (
                                <div className={`dropdown-menu-option ${index === hoveredIndex ? 'hovered' : ''}`} key={key}
                                     onClick={() => handleOptionClick(key)}>
                                    <div className="dropdown-menu-option-content" id={key}>
                                    <Location/>
                                        <p>{capitalizeFirstLetter(key.replaceAll("_", " "))}</p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="dropdown-menu-option">
                                <p>No options found</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dropdown;